.botao-editar-tabela {
  margin: 5px;
  cursor: pointer;
}
.botoes-editar-tabela {
  text-align: right;
  cursor: pointer;
}

.grecaptcha-badge {
  bottom: 90px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.chip-taxas,
.chip-pagamentos {
  width: 360px;
  height: 40px;

  @media (max-width: 500px) {
    width: 100%;
  }
}
